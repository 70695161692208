<template>
	<div class="profile-show">
		<div class="flex justify-between">
			<h1 class="profile-name-title">Settings: {{ name }}</h1>
			<button
				class="profile-header-btn"
				data-tooltip="Set Schedule"
				aria-label="Set Schedule"
				data-position="bottom"
				@click.prevent="onScheduleClick"
			>
				<font-awesome-icon
					fixed-width
					:icon="['fal', 'clock']"
					aria-hidden="true"
				/>
			</button>
		</div>
		<form
			v-if="partner"
			class="profile-container"
			@submit.prevent="onSubmit"
		>
			<div class="tab-container" @submit.prevent="onSubmit">
				<div class="tabs">
					<button
						v-for="tab in visibleTabs"
						:key="tab.name"
						type="button"
						:class="[
							'tab',
							{
								active: activeTab === tab.name,
								disabled: isTabDisabled(tab.name),
							},
						]"
						:style="{ width: `${100 / visibleTabs.length}%` }"
						:disabled="isTabDisabled(tab.name)"
						:data-tooltip="
							isTabDisabled(tab.name)
								? 'Please fill the required fields for the current tab.'
								: null
						"
						data-position="bottom"
						@click="selectTab(tab.name)"
					>
						{{ tab.label }}
					</button>
				</div>

				<!-- Tab Content for Configuration -->
				<div v-show="activeTab === 'config'" class="tab-content">
					<div class="card">
						<div class="card-title">
							<div class="card-title_left-setting-page">
								Configuration
							</div>
							<div class="card-title_right-setting-page">
								Address
							</div>
						</div>
						<div class="card-body">
							<div class="card-body_left-setting-page">
								<div class="card-body-container">
									<div class="form-group">
										<label
											class="form-label form-group-title-new"
										>
											Practice Name
										</label>
										<div
											class="nonEditable-text settings-input"
										>
											{{ form.name }}
										</div>
									</div>

									<div class="form-group">
										<label
											class="form-label form-group-title-new"
										>
											Forwarding Number
										</label>
										<div
											class="nonEditable-text settings-input"
										>
											{{
												partner.twilio_phone_number
													| formatPhoneNumber
											}}
										</div>
									</div>

									<div
										class="profile-section-single-line-details"
									>
										<div
											class="form-group form-group-profile-section-single-line-details"
										>
											<label
												for="office_phone_number"
												class="form-label form-group-title-new"
											>
												Office Phone Number
											</label>
											<input
												id="office_phone_number"
												v-model="
													form.office_phone_number
												"
												type="text"
												name="office_phone_number"
												class="form-input"
												placeholder="Phone number"
											/>
										</div>

										<div
											class="form-group form-group-profile-section-single-line-details"
										>
											<label
												for="office_fax_number"
												class="form-label form-group-title-new"
											>
												Office Fax Number
											</label>
											<input
												id="office_fax_number"
												v-model="form.office_fax_number"
												type="text"
												name="office_fax_number"
												placeholder="Fax number"
												class="form-input"
											/>
										</div>
									</div>

									<div class="form-group">
										<label for="fallback_email_1">
											Fallback Notification Email
										</label>
										<input
											id="fallback_email_1"
											v-model="form.fallback_email"
											required
											type="text"
											name="fallback_email_1"
											class="form-input"
											placeholder="Fallback notification email"
										/>
									</div>
									<div
										class="profile-section-single-line-details"
									>
										<div
											class="form-group form-group-profile-section-single-line-details"
										>
											<label
												for="fallback_phone_number"
												class="form-label form-group-title-new"
											>
												Fallback Notification Phone
												Number
											</label>
											<input
												id="fallback_phone_number"
												v-model="
													form.fallback_phone_number
												"
												required
												pattern="^[2-9]\d{9}$"
												title="The fallback phone number field must be a valid 10 digit number"
												type="text"
												name="fallback_phone_number"
												class="form-input"
												placeholder="Notification phone number"
											/>
										</div>
										<div
											class="form-group form-group-profile-section-single-line-details"
										>
											<label
												for="timezone"
												class="form-label form-group-title-new"
											>
												Timezone
											</label>
											<div class="relative">
												<select
													id="timezone"
													v-model="form.timezone"
													name="timezone"
													placeholder="Select a timezone"
													class="form-input"
													:class="[
														form.timezone
															? 'text-gray-700'
															: 'text-gray-500',
													]"
												>
													<option
														:value="null"
														disabled
														>Select a
														timezone</option
													>
													<option
														v-for="timezone in timezones"
														:key="timezone"
														:value="timezone"
													>
														{{ timezone }}
													</option>
												</select>
												<div
													class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
												>
													<font-awesome-icon
														class="fill-current h-4 w-4 mt-4 mr-2"
														:icon="[
															'far',
															'chevron-down',
														]"
													></font-awesome-icon>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="card-body_right-setting-page">
								<div class="card-body-container">
									<div class="form-group">
										<label
											for="address_1"
											class="form-label form-group-title-new"
										>
											Street Address
										</label>
										<input
											id="address_1"
											v-model="form.address_1"
											type="text"
											name="address_1"
											class="form-input"
											placeholder="Street address"
										/>
									</div>
									<div class="form-group">
										<label
											for="address_2"
											class="form-label form-group-title-new"
										>
											Address Line 2
										</label>
										<input
											id="address_2"
											v-model="form.address_2"
											type="text"
											name="address_2"
											class="form-input"
											placeholder="Address line 2"
										/>
									</div>
									<div
										class="profile-section-single-line-details"
									>
										<div
											class="form-group form-group-profile-section-single-line-details"
										>
											<label
												for="city"
												class="form-label form-group-title-new"
											>
												City
											</label>
											<input
												id="city"
												v-model="form.city"
												type="text"
												name="city"
												class="form-input"
												placeholder="City"
											/>
										</div>
										<div
											class="form-group form-group-profile-section-single-line-details"
										>
											<label
												for="state_province"
												class="form-label form-group-title-new"
											>
												State Or Province
											</label>
											<input
												id="state_province"
												v-model="form.state_province"
												type="text"
												name="state_province"
												class="form-input"
												placeholder="State or Province"
											/>
										</div>
									</div>
									<div
										class="profile-section-single-line-details"
									>
										<div
											class="form-group form-group-profile-section-single-line-details"
										>
											<label
												for="country"
												class="form-label form-group-title-new"
											>
												Country
											</label>
											<input
												id="country"
												v-model="form.country"
												type="text"
												name="country"
												class="form-input"
												placeholder="Country"
											/>
										</div>
										<div
											class="form-group form-group-profile-section-single-line-details"
										>
											<label
												for="postal_code"
												class="form-label form-group-title-new"
											>
												Postal Code
											</label>
											<input
												id="postal_code"
												v-model="form.postal_code"
												type="text"
												name="postal_code"
												class="form-input"
												placeholder="Postal code"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<div class="card-footer_left"></div>
							<div class="card-footer_right">
								<button
									class="action-button"
									type="submit"
									data-tooltip="Save"
									data-position="bottom"
									data-cy="save-button"
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>

				<!-- Tab Content for Voicemail Configuration -->
				<div v-show="activeTab === 'voicemail'" class="tab-content">
					<div class="card">
						<div class="card-title">
							Secondary Voicemail
						</div>
						<div class="card-body">
							<div class="card-body-container">
								<div class="icon-container">
									<span class="space-x-4">
										<button
											type="button"
											aria-label="Filter reports"
											data-position="top"
											data-tooltip="Help"
											@click.stop.prevent="onClickInfo"
										>
											<font-awesome-icon
												class="address-icon"
												:icon="[
													'far',
													'question-circle',
												]"
											/>
										</button>
										<button
											type="button"
											aria-label="Filter reports"
											data-position="top"
											data-tooltip="Copy Settings"
											@click.stop.prevent="
												copyVoicemailData
											"
										>
											<font-awesome-icon
												class="address-icon"
												:icon="['far', 'address-card']"
											/>
										</button>
									</span>
								</div>
								<div
									class="voicemail-section-single-line-details mb-2"
								>
									<div
										class="form-group form-group-voicemail-section-single-line-details mb-3"
									>
										<label class="form-label mb-0">
											Voicemail Retrieval Number
										</label>
										<div
											class="nonEditable-text settings-input"
										>
											{{
												partner.global_rsm_phone_number
													| formatPhoneNumber
											}}
										</div>
									</div>
									<div
										class="form-group form-group-voicemail-section-single-line-details"
									>
										<label class="form-label mb-0">
											Mailbox Number
										</label>
										<div
											class="nonEditable-text settings-input"
										>
											{{
												partner.twilio_phone_number
													| formatPhoneNumber
											}}
										</div>
									</div>
									<div
										class="form-group form-group-voicemail-section-single-line-details"
									>
										<label
											for="pass_code"
											class="form-label mb-0"
											>Passcode</label
										>
										<div class="relative">
											<input
												id="pass_code"
												v-model="form.passcode"
												autocomplete="new-password"
												:type="
													showPasscode
														? 'text'
														: 'password'
												"
												name="pass_code"
												class="form-input"
												placeholder="Passcode"
												maxlength="6"
												required
												@input="validatePasscode"
											/>
											<button
												type="button"
												data-tooltip="Show Passcode"
												class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-700"
												@click="
													showPasscode = !showPasscode
												"
											>
												<font-awesome-icon
													:icon="[
														showPasscode
															? 'fas'
															: 'fas',
														showPasscode
															? 'eye-slash'
															: 'eye',
													]"
												/>
											</button>
										</div>
										<div
											v-if="passcodeErrorMessage"
											class="text-red-500 text-sm mt-2"
										>
											{{ passcodeErrorMessage }}
										</div>
									</div>
								</div>
								<div class="form-group">
									<label
										for="email_notification"
										class="form-label ml-1"
									>
										Email Notifications
									</label>
									<div class="input-tag-container">
										<div
											class="tag-container"
											:class="{
												empty:
													fallbackEmailTags.length ===
													0,
											}"
										>
											<div
												v-for="(email,
												index) in fallbackEmailTags"
												:key="index"
												class="tag"
											>
												{{ email }}
												<span
													class="close mr-1"
													@click="removeEmail(index)"
													>x</span
												>
											</div>
											<input
												id="email_notifications"
												v-model="newEmail"
												type="email"
												class="form-input-fallback_email"
												:placeholder="
													fallbackEmailTags.length ===
													0
														? 'Add Email...'
														: 'Add Emails...'
												"
												@keydown.enter.prevent="
													addEmail
												"
												@keydown="handleBackspace"
												@keypress="
													checkForComma($event)
												"
												@input="
													debounceFilterEmailSuggestions(
														$event
													)
												"
												@blur="addEmail"
											/>
										</div>
										<ul
											v-if="emailSuggestions.length"
											:class="[
												'email-suggestions',
												{
													'open-upwards': shouldOpenUpwards,
												},
											]"
										>
											<li
												v-for="suggestion in emailSuggestions"
												:key="suggestion"
												class="suggestion-item"
												@mousedown="
													selectEmail(suggestion)
												"
											>
												{{ suggestion }}
											</li>
										</ul>
									</div>
									<div
										v-if="emailErrorMessage"
										class="text-red-500 text-sm"
									>
										{{ emailErrorMessage }}
									</div>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<div class="card-footer_left"></div>
							<div class="card-footer_right">
								<button
									class="action-button"
									type="submit"
									data-tooltip="Save"
									data-position="bottom"
									data-cy="save-button"
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>

				<!-- Tab Content for EMR Configuration -->
				<div
					v-show="activeTab === 'emr'"
					v-if="isEmrConfigured"
					class="tab-content"
				>
					<div>
						<div class="card">
							<div class="card-title">
								Configuration
							</div>
							<div class="card-body">
								<div class="card-body-container">
									<div
										class="form-group form-group-voicemail-section-single-line-details-test"
									>
										<label class="form-label mb-0">
											Provider
										</label>
										<div
											class="nonEditable-text settings-input"
										>
											{{ emrProviderName }}
										</div>
									</div>
									<div
										class="voicemail-section-single-line-details mb-2"
									>
										<div
											class="form-group form-group-voicemail-section-single-line-details"
										>
											<label
												for="username"
												class="form-label form-group-title-new"
											>
												Username
											</label>
											<div class="relative">
												<input
													id="username"
													v-model="
														form.emr_config.username
													"
													type="text"
													name="username"
													placeholder="Username"
													class="form-input"
													style="padding-right: 2rem;"
												/>
												<button
													type="button"
													style="position: absolute; right: 0.75rem; top: 50%; transform: translateY(-50%); color: black; font-size: 1rem; background: none; border: none; cursor: pointer; padding: 0; display: flex; align-items: center; justify-content: center;"
													@click="
														form.emr_config.username =
															''
													"
												>
													<font-awesome-icon
														fixed-width
														class="btn-icon text-gray-700 new-design-icon-sm"
														:icon="['fal', 'times']"
														style="padding-top: 0.10rem;"
													/>
												</button>
											</div>
										</div>
										<div
											class="form-group form-group-voicemail-section-single-line-details"
										>
											<label
												for="pass_word"
												class="form-label mb-0"
												>Password</label
											>
											<div class="relative">
												<input
													id="pass_word"
													v-model="
														form.emr_config.password
													"
													autocomplete="new-password"
													:type="
														showPasscode
															? 'text'
															: 'password'
													"
													name="pass_word"
													class="form-input"
													placeholder="Password"
												/>
												<button
													type="button"
													data-tooltip="Show Passcode"
													class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-700"
													@click="
														showPasscode = !showPasscode
													"
												>
													<font-awesome-icon
														:icon="[
															showPasscode
																? 'fas'
																: 'fas',
															showPasscode
																? 'eye-slash'
																: 'eye',
														]"
													/>
												</button>
											</div>
										</div>

										<div
											class="form-group form-group-voicemail-section-single-line-details"
										>
											<label
												for="destination_id"
												class="form-label form-group-title-new"
											>
												Destination ID
											</label>
											<div class="relative">
												<input
													id="destination_id"
													v-model="
														form.emr_config
															.destination_id
													"
													type="text"
													name="destination_id"
													placeholder="Destination ID"
													class="form-input"
													style="padding-right: 2rem;"
												/>
												<button
													type="submit"
													style="position: absolute; right: 0.75rem; top: 50%; transform: translateY(-50%); color: black; font-size: 1rem; background: none; border: none; cursor: pointer; padding: 0; display: flex; align-items: center; justify-content: center;"
													@click="
														form.emr_config.destination_id =
															''
													"
												>
													<font-awesome-icon
														fixed-width
														class="btn-icon text-gray-700 new-design-icon-sm"
														:icon="['fal', 'times']"
														style="padding-top: 0.10rem;"
													/>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="card-footer_left"></div>
								<div class="card-footer_right">
									<button
										class="action-button"
										type="submit"
										:data-tooltip="
											!isSaveDisabled ? 'Save' : null
										"
										data-position="bottom"
										data-cy="save-button"
										:disabled="isSaveDisabled"
									>
										Save
									</button>
								</div>
							</div>
						</div>
						<div v-if="shouldShowNoteTypes" class="card">
							<div class="card-title">
								Configure Note Types
							</div>
							<div class="card-body">
								<div class="card-body-container">
									<div class="coordination-note-container">
										<div
											class="coordination-note-container_left"
										>
											<div
												class="coordination-note-check-box-container"
											>
												<div
													v-for="note in sortedNoteTypes"
													:key="note.id"
													class="note-type-item"
												>
													<div
														class="custom-checkbox"
													>
														<input
															:id="
																`note-${note.id}`
															"
															v-model="
																selectedValues
															"
															type="checkbox"
															:value="note.id"
															:checked="
																isChecked(
																	note.id
																)
															"
															@change="
																onNoteTypeSelected(
																	note
																)
															"
														/>
														<label
															:for="
																`note-${note.id}`
															"
														></label>
													</div>

													<!-- Updated label with the note title -->
													<label
														:for="`note-${note.id}`"
														class="note-label"
													>
														{{ note.title }}
													</label>
												</div>
											</div>
										</div>
										<div
											class="coordination-note-container_right"
										>
											<div class="note-item-name">
												{{
													currentNoteTitle ||
														'Note Item'
												}}
											</div>
											<!-- Empty for now -->
											<div class="note-item-details">
												<div class="note-item-field">
													<label class="field-label"
														>Call Received:</label
													>
													<label
														class="field-label-disable"
														>%call-ended%</label
													>
												</div>

												<div class="note-item-field">
													<label class="field-label"
														>Message:</label
													>
													<label
														class="field-label-disable"
														>%message%</label
													>
												</div>
												<div class="note-item-field">
													<label class="field-label"
														>Notes:
													</label>
													<label
														class="field-label-disable"
														>%notes%</label
													>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="card-footer_left">
									<span v-if="showNoteWarning" class="warning"
										>At least one note type must be
										selected.</span
									>
								</div>
								<div class="card-footer_right">
									<button
										class="action-button"
										type="submit"
										data-tooltip="Save"
										data-position="bottom"
										data-cy="save-button"
									>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
		<edit-schedule-panel :show="panel" @close="panel = false" />
	</div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import EditSchedulePanel from '@/components/Panels/EditSchedulePanel.vue'
import intercomConfig from '@/config/intercom'
import _get from 'lodash/get'

/**
 * Get the empty partner form object.
 *
 * @return {Object}
 */
const getFormObject = () => {
	return {
		name: '',
		office_phone_number: '',
		office_fax_number: '',
		address_1: '',
		address_2: '',
		city: '',
		state_province: '',
		country: '',
		postal_code: '',
		fallback_email: '',
		fallback_phone_number: '',
		timezone: '',
		rsm_fallback_email: '',
		passcode: '',
		emr_config: {
			username: '',
			password: '',
			destination_id: '',
			note_types: '',
			metadata: '', // Adjust this type if it's not a simple string, after BE, PHASE 2
		},
	}
}

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		EditSchedulePanel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Returns the EMR provider's name from the partner's settings, or an empty string if not found.
		 */
		emrProviderName() {
			return _get(this.partner, 'emr_settings.provider.name', '')
		},

		/**
		 * Checks if the save button should be disabled based on differences between form values and EMR settings, and ensures valid credentials exist.
		 */
		isSaveDisabled() {
			//values from form
			const fromPasswordVal = this.form.emr_config.password
			const formtDestID = this.form.emr_config.destination_id
			const formUserName = this.form.emr_config.username
			//values from api
			const emrSettingsVal = this.partner?.emr_settings
			const apiValPass = emrSettingsVal.password
			const apiValDest = emrSettingsVal.destination_id
			const apiValUserName = emrSettingsVal.username

			if (
				fromPasswordVal !== apiValPass ||
				formtDestID !== apiValDest ||
				formUserName !== apiValUserName
			) {
				return
			} else {
				const emrSettings = _get(this.partner, 'emr_settings', {})
				const hasValidCredentials =
					(_get(emrSettings, 'username', '') || '').trim() !== '' &&
					(_get(emrSettings, 'password', '') || '').trim() !== '' &&
					(_get(emrSettings, 'destination_id', '') || '').trim() !==
						'' &&
					(_get(emrSettings, 'note_types', '') || '').trim() !== '' &&
					this.selectedValues.length > 0

				return hasValidCredentials && !this.isNoteTypesFetchError
			}
		},

		/**
		 * Checks if the partner has EMR settings configured in the Vuex store.
		 * @returns {boolean} True if EMR settings are present; otherwise, false.
		 */
		isEmrConfigured() {
			return Boolean(_get(this.$store.getters, 'partner.emr_settings'))
		},

		/**
		 * Returns the note types sorted alphabetically by title.
		 */
		sortedNoteTypes() {
			return this.noteTypes
				.slice()
				.sort((a, b) => a.title.localeCompare(b.title))
		},

		/**
		 * Filters and returns tabs, excluding the 'emr' tab if EMR is not configured.
		 */
		visibleTabs() {
			return this.tabs.filter(
				tab => tab.name !== 'emr' || this.isEmrConfigured
			)
		},

		/**
		 * Get the partner's name.
		 *
		 * @return {String}
		 */
		name() {
			return this.partner?.name || ''
		},

		/**
		 * Determines whether note types should be displayed, based on availability and error status.
		 */
		shouldShowNoteTypes() {
			return (
				this.noteTypes &&
				this.noteTypes.length > 0 &&
				!this.isNoteTypesFetchError
			)
		},

		/**
		 * Getter: Parses and returns fallback emails as an array of trimmed email addresses.
		 * Setter: Joins and sets the array of emails as a comma-separated string.
		 */
		fallbackEmailTags: {
			get() {
				let emails = this.form.rsm_fallback_email
				if (emails && emails.trim()) {
					return emails.split(/,|;|\s+/).filter(email => email.trim())
				}

				return []
			},
			set(newEmails) {
				this.form.rsm_fallback_email = newEmails.join(', ')
			},
		},

		/**
		 * Retrieves the partner ID from the current route's parameters and converts it to an integer.
		 */
		partnerId() {
			return parseInt(this.$route.params.partner)
		},

		...mapGetters({
			partner: 'partners/active',
		}),

		/**
		 * Email suggestions lenght
		 *
		 */
		shouldOpenUpwards() {
			return this.emailSuggestions.length > 2
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		isChecked(data) {
			const isSelected = this.selectedValues.find(value => value === data)
			return isSelected !== undefined
		},

		onNoteTypeSelected(note) {
			this.currentNoteTitle = note.title

			if (this.areAtLeastOneNoteSelected()) {
				this.showNoteWarning = false
			}
		},

		selectTab(tabName) {
			if (!this.isTabDisabled(tabName) || tabName !== this.activeTab) {
				this.fillForm()
				this.activeTab = tabName
				this.showPasscode = false
			}
		},

		isTabDisabled(tabName) {
			if (tabName === this.activeTab) return false

			switch (this.activeTab) {
				case 'config':
					return !this.isConfigComplete()
				case 'voicemail':
					return !this.isVoicemailComplete()
				case 'emr':
					return !this.isEMRComplete()
				default:
					return false
			}
		},

		isConfigComplete() {
			return this.form.fallback_email && this.form.fallback_phone_number
		},

		isVoicemailComplete() {
			return this.form.passcode
		},

		isEMRComplete() {
			return (
				this.form.emr_config.username &&
				this.form.emr_config.password &&
				this.form.emr_config.destination_id &&
				(this.isNoteTypesFetchError || this.areAtLeastOneNoteSelected())
			)
		},

		areAtLeastOneNoteSelected() {
			const isAnyNoteSelected = this.selectedValues.length > 0
			return isAnyNoteSelected
		},

		debounce(fn, delay) {
			let timeoutID
			return function(...args) {
				if (timeoutID) {
					clearTimeout(timeoutID)
				}
				timeoutID = setTimeout(() => {
					fn.apply(this, args)
				}, delay)
			}
		},

		/**
		 * Get the form field names/keys.
		 *
		 * @return {Array}
		 */
		fields() {
			return Object.keys(this.form)
		},

		isEMRConfiguredSwitch() {
			// return this.$store.getters?.partner?.emr_settings
			return this.partner?.emr_settings
		},

		isEMRValid() {
			const emrSettings = this.partner?.emr_settings
			return (
				emrSettings?.username &&
				emrSettings.username.trim() !== '' &&
				emrSettings?.password &&
				emrSettings.password.trim() !== '' &&
				emrSettings?.destination_id &&
				emrSettings.destination_id.trim() !== ''
			)
		},

		/**
		 * Fill the form with the partner's information.
		 *
		 * @return {void}
		 */
		fillForm() {
			if (!this.partner) {
				return this.reset()
			}

			this.fields().forEach(key => {
				if (
					this.partner[key] !== null &&
					typeof this.partner[key] !== 'undefined'
				) {
					if (
						[
							'office_phone_number',
							'office_fax_number',
							'fallback_phone_number',
						].includes(key)
					) {
						this.form[key] = this.partner[key].replace(/^\+1/, '')
					} else {
						this.form[key] = this.partner[key]
					}
				}
			})

			if (this.isEMRConfiguredSwitch()) {
				this.fetchCoordinationNoteTypes()
				if (this.isEMRValid()) {
					this.form.emr_config.note_types =
						this.partner.emr_settings.note_types || {}

					if (
						this.partner.emr_settings.note_types &&
						!this.isNoteTypesFetchError
					) {
						try {
							const partnerNotes = JSON.parse(
								this.partner.emr_settings.note_types
							).map(x => x.note_id)
							this.selectedValues = [...partnerNotes]
						} catch (error) {
							console.error('Error parsing note types:', error)
							this.selectedValues = []
						}
					} else {
						this.form.emr_config.note_types = []
						this.selectedValues = []
					}
				}
				this.form.emr_config.username =
					this.partner.emr_settings.username || ''
				this.form.emr_config.password =
					this.partner.emr_settings.password || ''
				this.form.emr_config.destination_id =
					this.partner.emr_settings.destination_id || ''
				this.form.emr_config.metadata =
					this.partner.emr_settings.metadata || {}
				this.form.emr_config.note_types = []
			}
			this.activeTab = 'config'
			this.showNoteWarning = false
			this.showPasscode = false

			this.passcodeErrorMessage = ''
			this.emailErrorMessage = ''
			this.showPasscode = false
			this.form.rsm_fallback_email =
				this.partner.rsm_fallback_email ||
				this.partner.fallback_email ||
				''
		},

		/**
		 * Handle the schedule click event.
		 *
		 * @return {void}
		 */
		onScheduleClick() {
			this.panel = true
		},

		removeLastEmail() {
			if (this.fallbackEmailTags.length > 0) {
				this.fallbackEmailTags.pop()
				this.updateFormData()
			}
		},

		handleBackspace(event) {
			if (
				(event.key === 'Backspace' || event.which === 8) &&
				!this.newEmail
			) {
				this.removeLastEmail()
			}
		},

		updateFormData() {
			this.form.rsm_fallback_email = this.fallbackEmailTags.join(', ')
		},

		removeEmail(index) {
			const emails = this.fallbackEmailTags.filter((_, i) => i !== index)
			this.fallbackEmailTags = emails
			if (emails.length === 0) {
				this.form.rsm_fallback_email = null
			} else {
				this.form.rsm_fallback_email = emails.join(', ')
			}
		},

		addEmail() {
			const emails = this.newEmail.split(/[;, \t\n]+/)
			emails.forEach(email => {
				if (this.validateEmail(email)) {
					const trimmedEmail = email.trim()
					if (trimmedEmail) {
						const newEmails = trimmedEmail.split(/[;, \t\n]+/)
						const existingEmails = (
							this.form.rsm_fallback_email || ''
						)
							.trim()
							.split(/[;, \t\n]+/)
							.filter(email => email.trim())
						const allEmails = [...existingEmails, ...newEmails]
						this.form.rsm_fallback_email = allEmails.join(', ')
						if (!this.form.rsm_fallback_email) {
							this.form.fallback_email = this.form.rsm_fallback_email
						}
					}
				}
			})
			this.newEmail = ''
			this.emailSuggestions = []
		},

		async fetchEmailSuggestions(searchTerm) {
			if (!searchTerm) return

			const partnerId = parseInt(this.$route.params.partner)
			const apiUrl = `${process.env.VUE_APP_API_URL}/api/v4/partners/${partnerId}/search?field=email&role=office_manager&search_value=${searchTerm}`

			try {
				const response = await fetch(apiUrl, {
					headers: {
						Authorization: `Bearer ${this.$store.state.auth.session.access_token}`,
					},
				})

				if (!response.ok) {
					throw new Error('Failed to fetch email suggestions')
				}

				const data = await response.json()

				const fallbackEmails = this.form.rsm_fallback_email
					.split(',')
					.map(email => email.trim())

				const uniqueSuggestions = data
					.map(suggestion => suggestion.trim())
					.filter(email => !fallbackEmails.includes(email))

				this.emailSuggestions = uniqueSuggestions
			} catch (error) {
				console.error(
					'fetchEmailSuggestions: Error fetching email suggestions',
					error
				)
			}
		},

		validateNoteTypes() {
			if (
				this.selectedValues.length === 0 &&
				this.noteTypes &&
				this.noteTypes.length > 0
			) {
				this.noteTypesErrorMessage =
					'At least one note type must be selected.'
				return false
			}
			this.noteTypesErrorMessage
			return true
		},

		async fetchCoordinationNoteTypes() {
			const partnerId = parseInt(this.$route.params.partner)
			const apiUrl = `${process.env.VUE_APP_API_URL}/api/v4/emr/note-types?partner_id=${partnerId}`

			try {
				const response = await fetch(apiUrl, {
					headers: {
						Authorization: `Bearer ${this.$store.state.auth.session.access_token}`,
					},
				})

				if (!response.ok) {
					throw new Error('Failed to fetch coordination note types')
				}

				const data = await response.json()

				if (Array.isArray(data.note_types)) {
					this.noteTypes = data.note_types

					let partnerNoteTypes = []
					if (data.emr_settings && data.emr_settings.note_types) {
						try {
							partnerNoteTypes = JSON.parse(
								data.emr_settings.note_types
							)
						} catch (error) {
							console.error(
								'Error parsing emr_settings.note_types:',
								error
							)
						}
					} else {
						console.warn(
							'emr_settings or emr_settings.note_types is missing or empty.'
						)
					}

					this.selectedNoteTypes = this.noteTypes.filter(note =>
						partnerNoteTypes.some(
							selectedNote => selectedNote.note_id === note.id
						)
					)
					this.isNoteTypesFetchError = false
				} else {
					console.warn(
						'Note Types data is not an array or is missing.'
					)
					this.isNoteTypesFetchError = true
				}
			} catch (error) {
				console.error(
					'fetchCoordinationNoteTypes: Error fetching coordination note types',
					error
				)
				this.isNoteTypesFetchError = true
			}
		},

		validateEmail(email) {
			if (!email) {
				this.emailErrorMessage = ''
				return true
			}

			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			if (!emailRegex.test(email)) {
				this.emailErrorMessage = 'Please enter a valid email address.'
				return false
			}

			if (this.fallbackEmailTags.includes(email)) {
				this.emailErrorMessage =
					'Duplicate email address. Please enter a unique email.'
				return false
			}

			if (/[A-Z]/.test(email)) {
				this.emailErrorMessage =
					'Email addresses cannot contain capital letters.'
				return false
			}

			this.emailErrorMessage = ''
			return true
		},

		checkForComma(event) {
			if (event.key === ',') {
				event.preventDefault()
				this.addEmail()
			}
		},

		isSequential(passcode) {
			for (let i = 0; i < passcode.length - 2; i++) {
				if (
					parseInt(passcode[i]) + 1 === parseInt(passcode[i + 1]) &&
					parseInt(passcode[i + 1]) + 1 === parseInt(passcode[i + 2])
				) {
					return true
				}
			}
			return false
		},

		isRepeating(passcode) {
			for (let i = 0; i < passcode.length - 2; i++) {
				if (
					passcode[i] === passcode[i + 1] &&
					passcode[i + 2] === passcode[i]
				) {
					return true
				}
			}
			return false
		},

		async selectEmail(suggestion) {
			const selectedEmail = suggestion

			if (this.validateEmail(selectedEmail)) {
				const newEmails = selectedEmail.split(/[;, \t\n]+/)
				const existingEmails = (this.form.rsm_fallback_email || '')
					.trim()
					.split(/[;, \t\n]+/)
					.filter(email => email.trim())
				const allEmails = [...existingEmails, ...newEmails]
				this.form.rsm_fallback_email = allEmails.join(', ')
				this.newEmail = ''
				this.emailErrorMessage = ''
			} else {
				console.log(
					'selectEmail: Email validation failed for',
					selectedEmail
				)
			}
		},

		async filterEmailSuggestions() {
			const searchTerm = this.newEmail.toLowerCase().trim()
			if (searchTerm.length >= 2) {
				this.emailSuggestions = this.allEmails.filter(email => {
					const lowerCaseEmail = email.toLowerCase()
					return (
						lowerCaseEmail.includes(searchTerm) &&
						!this.fallbackEmailTags.includes(email)
					)
				})
			} else {
				this.emailSuggestions = []
			}
		},

		debounceFilterEmailSuggestions: function(event) {
			const searchTerm = event.target.value.trim()
			this.debounce(function() {
				if (searchTerm.length >= 2) {
					this.fetchEmailSuggestions(searchTerm)
				} else {
					this.emailSuggestions = []
				}
			}, 300).apply(this)
		},

		validatePasscode() {
			const passcode = this.form.passcode

			if (!/^\d+$/.test(passcode)) {
				this.passcodeErrorMessage = 'Passcode can only contain digits.'
				return false
			}

			if (passcode.length < 6) {
				this.passcodeErrorMessage =
					'Passcode must be at least 6 digits long.'
				return false
			}

			if (this.isSequential(passcode)) {
				this.passcodeErrorMessage =
					'Passcode cannot contain 3 or more sequential digits.'
				return false
			}

			if (this.isRepeating(passcode)) {
				this.passcodeErrorMessage =
					'Passcode cannot contain 3 or more repeating digits.'
				return false
			}

			this.passcodeErrorMessage = ''
			return true
		},

		getNoteTypesPayload() {
			const fromPasswordVal = this.form.emr_config.password
			const formtDestID = this.form.emr_config.destination_id
			const formUserName = this.form.emr_config.username

			const emrSettingsVal = this.partner?.emr_settings || {}
			const apiValPass = emrSettingsVal.password
			const apiValDest = emrSettingsVal.destination_id
			const apiValUserName = emrSettingsVal.username

			let noteTypesPayload

			if (
				fromPasswordVal !== apiValPass ||
				formtDestID !== apiValDest ||
				formUserName !== apiValUserName
			) {
				noteTypesPayload = []
				this.selectedValues = []
			} else {
				noteTypesPayload = this.selectedValues
					.map(noteId => {
						const note = this.noteTypes.find(n => n.id === noteId)
						if (note) {
							return {
								note_id: note.id,
								note_name: note.title,
							}
						} else {
							return null
						}
					})
					.filter(note => note !== null)
			}

			return noteTypesPayload
		},

		/**
		 * Handle the on submit event.
		 *
		 * @return {void}
		 */
		async onSubmit() {
			if (
				this.partner?.emr_settings &&
				!this.areAtLeastOneNoteSelected() &&
				!this.isNoteTypesFetchError
			) {
				this.showNoteWarning = true
				return
			} else {
				this.showNoteWarning = false
			}

			const noteTypesPayload = this.getNoteTypesPayload()

			if (!this.validatePasscode()) {
				this.$alert.error(this.passcodeErrorMessage)
				return
			}
			if (!this.form.rsm_fallback_email && this.form.fallback_email) {
				this.form.rsm_fallback_email = this.form.fallback_email
			}
			if (this.form.rsm_fallback_email) {
				const emails = this.form.rsm_fallback_email
					.split(/,|;|\n|\s/)
					.map(email => email.trim())
					.filter(email => email)
				const duplicates = this.findDuplicates(emails)
				if (duplicates.length > 0) {
					this.$alert.error(
						`Duplicate emails found: ${duplicates.join(', ')}`
					)
					return
				}

				this.form.rsm_fallback_email = emails.join(', ')
			}

			const payload = {
				partner: {
					id: this.partner.id,
					name: this.form.name,
					office_phone_number: this.form.office_phone_number,
					office_fax_number: this.form.office_fax_number,
					address_1: this.form.address_1,
					address_2: this.form.address_2,
					city: this.form.city,
					state_province: this.form.state_province,
					postal_code: this.form.postal_code,
					rsm_fallback_email: this.form.rsm_fallback_email,
					country: this.form.country,
					fallback_email: this.form.fallback_email,
					fallback_phone_number: this.form.fallback_phone_number,
					timezone: this.form.timezone,
					passcode: this.form.passcode,
				},
				emr_config: {
					username: this.form.emr_config.username,
					password: this.form.emr_config.password,
					destination_id: this.form.emr_config.destination_id,
					note_types: noteTypesPayload,
					metadata: this.form.emr_config.metadata,
				},
			}

			try {
				await this.update({
					id: this.partner.id,
					fromSettings: true,
					payload: payload,
				})
				this.$alert.success('partners.success')
				if (this.isEMRConfiguredSwitch()) {
					this.fetchCoordinationNoteTypes()
				}
			} catch (e) {
				this.$alert.error('partners.error')
			}
		},

		findDuplicates(emails) {
			const duplicates = []
			const emailMap = new Map()

			emails.forEach(email => {
				const normalizedEmail = email.toLowerCase()
				if (emailMap.has(normalizedEmail)) {
					duplicates.push(email)
				} else {
					emailMap.set(normalizedEmail, true)
				}
			})
			return duplicates
		},

		/**
		 * Reset the form back to its initial state.
		 *
		 * @return {void}
		 */
		reset() {
			this.form = getFormObject()
		},

		/**
		 * Copy the voicemail data to the clipboard.
		 *
		 * @return {void}
		 */
		copyVoicemailData() {
			if (
				!this.form.rsm_fallback_email ||
				!this.validatePasscode(this.form.passcode) ||
				!this.form.fallback_email
			) {
				this.$alert.error(
					'Please fill in the email notifications and enter a valid passcode to copy settings.'
				)
				return
			}

			const formattedGlobalRsmPhoneNumber = this.partner.global_rsm_phone_number
				.substring(2)
				.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
			const formattedTwilioPhoneNumber = this.partner.twilio_phone_number
				.substring(2)
				.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

			const voicemailData = `
Secondary Voicemail Instructions

The Secondary Voicemail system ensures that all messages are captured should there be a momentary service interruption with our partners, platform, or your IVR. When a message is left, an email with instructions to retrieve the message is sent to your Email Notification recipients. The configuration and instructions for your mailbox number, passcode, and email recipients are defined in your Office Manager Dashboard, Settings under Secondary Voicemail.

Important Notes:
- Multiple users can retrieve messages at the same time.
- Clarus support does not have your passcode.
- Messages will not appear in your Office Dashboard or Clarus App at this time.

Configuration of your Secondary Voicemail service:

Update your Email Notification recipients to a list of important users who may need to be notified if a message is received, such as your phone staff or providers. This service supports more than one recipient. The initial email is copied from your fallback notification email. Click the Card icon to copy them to your clipboard for printing, share with your providers, or save them to shared contacts, as they might not be available during an interruption.

To retrieve your messages:
1. Dial the voicemail retrieval number: ${formattedGlobalRsmPhoneNumber}.
2. Enter your ten-digit mailbox number: ${formattedTwilioPhoneNumber}.
3. Enter your passcode: ${this.form.passcode}.
4. You can play and re-play each message until you have captured all the details.
5. Once you play the next message, you will not be able to listen to the previous or older messages.
                `

			navigator.clipboard
				.writeText(voicemailData)
				.then(() => {
					this.$alert.success(
						'Secondary Voicemail data copied to clipboard'
					)
				})
				.catch(err => {
					console.error('An error occurred:', err)
					this.$alert.error('Failed to copy Secondary Voicemail data')
				})
		},

		onClickInfo() {
			if (window.Intercom && intercomConfig.RSM_ARTICLE_ID) {
				window.Intercom('showArticle', intercomConfig.RSM_ARTICLE_ID)
			}
		},

		onPartnerChange(newPartner, oldPartner) {
			if (!newPartner || !oldPartner) {
				return
			}

			if (newPartner.id !== oldPartner.id) {
				this.fillForm()
			}
		},

		async getPartnerData() {
			try {
				await [this.getPartner(this.partner.id)]
			} catch (e) {
				this.$alert.error('default.error')
			}
		},

		...mapActions({
			update: 'partners/update',
			getPartner: 'partners/find',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Index',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the active partners.
		 */

		partner: {
			immediate: true,
			handler: 'onPartnerChange',
		},

		partners: {
			immediate: true,
			handler: 'fillForm',
		},

		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (to.params.partner !== from.params.partner) {
				this.reset()
				this.fillForm()
				this.getPartnerData()
			}
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.getPartnerData()
		this.timezones = moment.tz.zonesForCountry('us')
		if (!this.form.rsm_fallback_email && this.form.fallback_email) {
			this.form.rsm_fallback_email = this.form.fallback_email
		}

		if (this.$store.getters.partner?.emr_settings?.note_types) {
			const partnerNotes = JSON.parse(
				this.$store.getters.partner.emr_settings.note_types
			).map(x => x.note_id)
			this.selectedValues = [...partnerNotes]
		}
	},

	mounted() {
		if (this.isEMRConfiguredSwitch()) {
			this.fetchCoordinationNoteTypes()
		}
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			form: getFormObject(),
			panel: false,
			timezones: [],
			showPasscode: false,
			newEmail: '',
			errorMessage: '',
			passcodeErrorMessage: '',
			emailSuggestions: [],
			emailErrorMessage: '',
			allEmails: [],
			noteTypes: [],
			selectedNoteTypes: [],
			selectedValues: [],
			currentNoteTitle: '',
			isNoteTypesFetchError: false,
			showNoteWarning: false,
			tabs: [
				{ name: 'config', label: 'Configuration' },
				{ name: 'voicemail', label: 'Secondary Voicemail' },
				{ name: 'emr', label: 'EMR Configuration' },
			],
			activeTab: 'config',
		}
	},
}
</script>

<style scoped>
.coordination-note-box {
	border: 1px solid #ccc;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	width: 100%;
	margin: 20px auto;
	display: flex;
	box-sizing: border-box;
}

.section-1 {
	width: 50%;
	max-height: 300px; /* Fixed height for Section 1 */
	overflow-y: auto; /* Make Section 1 scrollable */
	padding-right: 10px;
	box-sizing: border-box;
}

.section-2 {
	width: 50%;
	border: 1px dashed #ccc; /* Visual placeholder for Section 2 */
	height: 300px; /* Match height with Section 1 for consistency */
	background-color: #fff;
}

.note-type-item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

input[type='checkbox'] {
	margin-right: 10px;
}

.note-label {
	width: 100%;
}
</style>
